<template>
    <div v-if="is_loading_provider">
        <cc-loader-full />
    </div>
    <div v-else class="makeorder-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Monitor de Cotação
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link :to="'/cliente/monitores/vencedores/' + $route.params.id" class="page-route-select-text">
                Comparativo de Preço
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Não Digitados
            </div>
        </div>
        <div class="makeorder-page-title-container">
            <div class="makeorder-page-title">Realizar Pedido</div>
            <div>
                <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate_provider(-1)">skip_previous</span>
                <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate_provider(1)" style="margin-left: 1vw;">skip_next</span>
            </div>
        </div>
        <div class="makeorder-page-info-container">
            <div class="makeorder-page-info-texts">
                <div class="makeorder-page-info-buyer-colum">
                    <div class="makeorder-page-info-buyer-title-text">Comprador</div>
                    <div class="makeorder-page-info-first-line makeorder-page-info-subtitle">Comprador:</div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.loj_nomeFantasia.toUpperCase() }}</div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.loj_endereco }}, {{ current_subrequest.loj_bairro }} - {{ current_subrequest.cid_nome }}/{{ current_subrequest.est_sigla }}</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Fone:</div>
                        <div class="makeorder-page-info-text" v-if="current_subrequest.loj_telefone">{{ current_subrequest.loj_telefone | VMask('(##) #####-####')  }}</div>
                        <div class="makeorder-page-info-text" v-else>--</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">CNPJ:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.loj_cnpj | cnpj }}</div>
                    </div>
                </div>
                <div class="makeorder-page-info-suplier-colum">
                    <div class="makeorder-page-info-buyer-title-text">Fornecedor</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-suplier-raiting-text">{{ current_subrequest.for_nome }}</div>
                        <span class="material-icons-outlined makeorder-page-info-suplier-raiting-icon">grade</span>
                        <div>{{current_subrequest.rate.toFixed(1)}}</div>
                    </div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.usu_nome }}</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">E-mail:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.usu_email }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Fone:</div>
                        <img class="makeorder-page-info-whats-icon" src="@/assets/WhatsApp_black.svg" alt="mandar mensagem">
                        <a class="makeorder-page-info-text" v-if="current_subrequest.usu_telefone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(current_subrequest.usu_telefone)" target="_blank">{{ current_subrequest.usu_telefone | VMask('(##) #####-####')  }}</a>
                        <div class="makeorder-page-info-text" v-else>--</div>
                    </div>
                </div>
                <div class="makeorder-page-info-details-colum">
                    <div class="makeorder-page-info-buyer-title-text">Detalhe do Pedido</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Entrega:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.data_entrega | formatDateOnly }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Tipo de Entrega:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.tpc_nome }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Faturamento:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.faturamento_minimo | currency }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Cond. de Pgto:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.forma }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Status:</div>
                        <div v-if="!current_subrequest.id_pedido" class="makeorder-page-info-status-pending">pendente</div>
                        <div v-else class="makeorder-page-info-status-created">gerado</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="makeorder-page-buttons-row">
            <div class="makeorder-page-buttons-total">
                <span class="material-icons-outlined makeorder-page-buttons-total-icon">attach_money</span>
                Valor Total: {{ this.current_subrequest.total | currency }}
            </div>
            <div class="makeorder-page-buttons-blank"></div>
            <StandardButton :action="sendBack" :iconName="'arrow_back'" :text="'Voltar'"/>
            <StandardButton class="makeorder-page-button-right" :action="print" :iconName="'print'" :text="'Imprimir'"/>
            <div class="makeorder-page-button-order" v-on:click="order" v-if="can('REQUEST_ORDER', 'CREATE') && !current_subrequest.id_pedido && backRoute == 1">
                <span class="material-icons-outlined makeorder-page-button-order-icon">shopping_cart</span>
                Realizar Pedido
            </div>
        </div>
        <div class="row prods">
            <div class="col pr-1">
                <ProductsMatrixComponent
                    v-if="current_subrequest"
                    :id="$route.params.id"
                    :min_fat="current_subrequest && current_subrequest.faturamento_minimo ? parseFloat(current_subrequest.faturamento_minimo) : 0"
                    :seller_id="$route.params.sellerid"
                    :provider="current_subrequest"
                    :reload="trigger"
                    :is_printing="is_printing"
                    :is_loading="is_loading"
                    :buyer="store"
                    @update="update"
                ></ProductsMatrixComponent>
                <cc-seller-review
                    :seller="{ usu_id: current_subrequest.vendedor_id, usu_nome: current_subrequest.usu_nome }"
                    v-if="show_reviews"
                    @close="show_reviews = false"
                />
                <cc-loader-full
                    v-show="is_printing"
                    :text="'Gerando documento para impressão, aguarde...'" />
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@/components/ui/buttons/button.component"
import Pagination from "@/components/cliente/base-components/Pagination";
import Typings from "@/components/lists/typings.component";
import ProductsMatrixComponent from "./_products-matrix.component";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import OrderService from "@/services/OrderService";
import RequestsService from "@/services/v2/requests.service";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import WinnerProducts from "@/components/partials/_products";
import AuthService from "@/services/AuthService";
import SellerReviewModal from '@/modals/seller-review/seller-review.modal';
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapGetters } from 'vuex'

export default {
    mixins: [ perm_mixin ],
    components: {
        ccButton: Button,
        ccPagination: Pagination,
        ccProducts: WinnerProducts,
        ProductsMatrixComponent,
        ccTypings: Typings,
        popoverLastBuy,
        'cc-seller-review' : SellerReviewModal,
        StandardButton
    },
    data() {
        return {
            trigger: false,
            is_loading: false,
            is_loading_provider: false,
            show_reviews: false,
            CONSTANTS: CONSTANTS,
            backRoute: 1,
            backOrdered: 0,
            backRouteName: "cliente-monitores-vencedores",
            sellers: [],
            openedPopovers: [],
            orderService: new OrderService(),
            quotationService: new QuotationService(),
            service: new RequestsService(),
            current_subrequest: null,
            store: null,
            stores: [],
            search_terms: null,
            subrequests: [],
            all_subrequests: [],
            products: [],
            is_printing: false,
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações"
                },
                {
                    link: CONSTANTS.ROUTE_MONITORS + "/vencedores/" + this.$route.params.id,
                    name: "Cotação " + this.$route.params.id
                },
                {
                    name: "Pré-Pedidos"
                },
                {
                    name: "Realizar Pedido"
                }
            ]
        }
    },
    methods: {
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        sendBack() {this.$router.push({ name: this.backRouteName, params: { id: this.$route.params.id, tab: 'pedidos', request_id: this.$route.params.id }, query: { po: this.backRoute, o: this.backOrdered } })},
        print() {
            this.is_printing = true
            setTimeout(() => {
                this.$htmlToPaper('print-matrix', { name: 'pedido.pdf' }, () => {
                    this.current_order = null
                    this.order_data = null
                    window.document.title = 'Club da Cotação'
                    this.is_printing = false
                });
                this.is_printing = false
            }, 1500)
        },
        order() {

            let amount = ((this.current_subrequest.total < (this.current_subrequest.faturamento_minimo || 0)) ? this.current_subrequest.total - this.current_subrequest.faturamento_minimo : 0);

            let confirmation_text = "<h2 style=''>Confirma realização do pedido?</h2>"
            confirmation_text += "</br><span>Seu pedido será enviado ao fornecedor!</span>"

            if(amount < 0) {
                confirmation_text = "<h2 style='color:#d33'>Atenção! Faturamento minimo não atingido</h2>"
                confirmation_text += "</br><span>Deseja continuar?</span>"
            }

            let data = {
                idcotacao: this.$route.params.id,
                idvendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid
            };
            this.$swal.fire({
                html: confirmation_text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true;
                    this.request_order(data).then(() => this.trigger = !this.trigger)
                }
            })
        },
        request_order(data, prevent_feedback = false) {
            return this.quotationService.order(data).then(response => response.data)
            .then(data => {
                if(!prevent_feedback){
                    this.is_loading = false;
                    this.$swal.fire(
                        "Realizado!",
                        "Seu pedido foi realizado com sucesso.",
                        "success"
                    )
                    this.update();
                }
            }, err => {
                this.is_loading = false;
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao realizar o pedido.",
                    "error"
                )
            })
        },
        navigate_provider(direction) {
            var s = null;
            for (var i = 0; i < this.subrequests.length; i++) {
                s = this.subrequests[i];
                if (s.vendedor_id == this.$route.params.sellerid && s.for_id == this.$route.params.providerid) {
                    if (direction == 1) {
                        if (this.subrequests.length > i + 1) {
                            s = this.subrequests[i + 1];
                            break;
                        } else if (this.subrequests.length == i + 1) {
                            s = this.subrequests[0];
                            break;
                        }
                    } else if (direction == -1) {
                        if (i == 0) {
                            s = this.subrequests[this.subrequests.length - 1];
                        } else {
                            s = this.subrequests[i - 1];
                        }
                        break;
                    }
                }
            }
            this.$router.push({
                name: "request-matrix-overview",
                params: {
                    id: this.$route.params.id,
                    sellerid: s.vendedor_id,
                    providerid: s.for_id
                },
                query: {
                    cli_id: this.$route.query.cli_id
                }
            });
            this.trigger = !this.trigger
            this.update();
        },
        cancel_order() {
            let data = {
                numeroCotacao: `${this.$route.params.id}`,
                vendedor: `${this.$route.params.sellerid}`,
                loja: `${this.$route.params.sid}`
            };
            this.$swal.fire({
                title: "Confirma cancelamento do pedido?",
                text: "Seu pedido será cancelado!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, cancelar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true
                    let subs = this.all_subrequests.filter(sub => sub.vendedor_id == data.vendedor && sub.id_pedido && sub.for_id == this.$route.params.providerid)
                    subs.forEach((sub, k) => {
                        this.request_order_cancelling({...data, loja: `${sub.loj_id}` }, k+1 == subs.length).then(() => {
                            if(k+1 == subs.length) {
                                this.trigger = !this.trigger
                            }
                        })
                    })
                }
            });
        },
        request_order_cancelling(data, prevent_feedback = false) {
            return this.quotationService.cancel_order(data).then(response => response.data)
            .then(data => {
                if(!prevent_feedback) {
                    this.$swal.fire(
                        "Sucesso!",
                        "Seu pedido foi cancelado com sucesso.",
                        "success"
                    )
                    this.is_loading = false
                    this.update();
                }
            }, err => {
                this.is_loading = false
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao cancelar o pedido.",
                    "error"
                )
            })
        },
        reload_current_provider() {
            this.load_subrequests()
            .catch(error => ErrorHandlerService.handle(error, this.$store))
        },
        checkReturnRoute() {
            if(this.current_subrequest) {
                if(!this.current_subrequest.id_pedido) {
                    this.backOrdered = 0;
                } else if(this.current_subrequest.id_pedido) {
                    this.backOrdered = 1;
                }
            }
        },
        filter_products(products) {
            if(products) {
                Object.values(products).forEach(p => {
                    p.visible = p.vencedores.some(winner => winner.idVendedor == this.$route.params.sellerid && winner.idFornecedor == this.$route.params.providerid)
                })
            }
        },
        update(section = null) {
            this.is_loading_provider = true;
            this.load_subrequests().then(() =>{
                this.checkReturnRoute();
                this.$forceUpdate();
                this.is_loading_provider = false;
            })
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        load_subrequests() {
            return this.service.subrequests_with_sellers_for(this.$route.params.id)
            .then(response => response.data).then(data => {
                this.subrequests = this.group_subrequests(data);
                this.all_subrequests = data
                let next = this.subrequests.find(s => s.for_id == this.$route.params.providerid && s.vendedor_id == this.$route.params.sellerid)
                if(next) {
                    this.current_subrequest = next
                    this.sort_subrequests()
                } else {
                    this.subrequests.push(this.current_subrequest)
                    this.sort_subrequests()
                    this.current_subrequest.total = 0
                }
            })
        },
        group_subrequests(data) {
            let result = []
            data.forEach(s => {
                var found = result.find(ss => ss.vendedor_id == s.vendedor_id)
                if(found) {
                    found.total += s.total
                    return
                } else {
                    found = s
                }
                result.push(found)
            })
            return result
        },
        sort_subrequests() {
            let result = []
            result = result.concat(this.subrequests.filter(s => s.total < s.faturamento_minimo).sort((a, b) => {
                let total1 = a.total
                let total2 = b.total
                if (total1 > total2) return 1
                if (total2 > total1) return -1
                return 0
            }))
            result = result.concat(this.subrequests.filter(s => s.total >= s.faturamento_minimo).sort((a, b) => {
                let total1 = a.total
                let total2 = b.total
                if (total1 > total2) return 1
                if (total2 > total1) return -1
                return 0
            }))
            this.subrequests = result;
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                { name: "Painel de Operações", route: 'monitors-page' },
                { name: "Cotações", route: 'monitors-page' },
                { name: `#${this.$route.params.id}`, route: 'cliente-monitores-vencedores' },
                { name: "detalhes" }
            ])
        }
    },
    async created() {
        this.update();
        this.update_breadcrumbs()
        this.stores = await this.$store.dispatch('user/fetch_stores', this.$route.query.cli_id)
        this.store = this.stores.find(s => s.loj_matriz == '1')
        if (this.$route.query.backRoute == 2) {
            this.backRoute = 2;
            this.backRouteName = this.$route.params.backRouteName;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "./details.page";

    .prods {
        #print-matrix {
            display: none;
        }
    }

.makeorder-page{
    padding-right: 3.5vw;
    font-size: 1vw;
}
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color:  var(--primary-color);
  margin-right: 20px;
}
.makeorder-page-title{
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
}
.makeorder-page-title-container{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.makeorder-page-title-icons{
    border: 2px solid #FF7110;
    color: var(--primary-color);
    padding: 0.5vh 1vw;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        background-color: var(--primary-color);
        color: white;
    }
}
.makeorder-page-info-container{
    display: flex;
    margin-top: 5vh;
    padding-left: 5vw;
}
.makeorder-page-info-buyer-colum{
    flex: 1;
}
.makeorder-page-info-suplier-colum{
    flex: 1;
}
.makeorder-page-info-texts{
    display: flex;
    flex: 1;
}
.makeorder-page-info-details-colum{
    flex: 1;
}
.makeorder-page-info-obs-colum{
    width: 25%;
}
.makeorder-page-info-buyer-title-text{
    font-weight: 700;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #606060;
}
.makeorder-page-info-first-line{ margin-top: 2vh; }
.makeorder-page-info-subtitle{
    font-weight: 500;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #707070;
    margin-right: 0.5vw;
}
.makeorder-page-info-text{
    font-weight: 300;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #A1A1A1;
}
.makeorder-page-info-suplier-raiting{
    margin-top: 1.5vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}
.makeorder-page-info-suplier-raiting-text{
    background: rgba(42, 205, 114, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #30AA4C;
    margin-right: 2vw;
}
.makeorder-page-info-suplier-raiting-icon{
    color: #FFCA2B;
    font-size: 2em;
}
.makeorder-page-info-whats-icon{
    filter: brightness(0) saturate(100%) invert(53%) sepia(11%) saturate(2678%) hue-rotate(81deg) brightness(100%) contrast(89%);
    height: 3.5vh;
    margin-right: 1.5vw;
}
.makeorder-page-info-status-created{
    font-weight: 500;
    font-size: 1.1em;
    color: #2ACD72;
}
.makeorder-page-info-status-pending{
    font-weight: 500;
    font-size: 1.1em;
    color: #f39c12;
}
.makeorder-page-info-obs-textarea{
    resize: none;
    padding: 1vh 1vw;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    margin-top: 0.5vh;
}
.makeorder-page-info-obs-textarea:focus{outline: none;}
.makeorder-page-info-obs-title-container{
    display: flex;
}
.makeorder-page-info-obs-title-icon{
    font-size: 1.4em;
    margin-left: 1vw;
    color: lightgreen;
    cursor: pointer;
}
.makeorder-page-buttons-row{
    display: flex;
    margin-top: 3.5vh;
}
.makeorder-page-buttons-total{
    display: flex;
    padding: 1vh 1vw;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #202020;
    margin-right: 2vw;
}
.makeorder-page-buttons-total-icon{
    font-size: 1.75em;
    color: var(--primary-color);
}
.makeorder-page-buttons-blank{flex: 1;}
.makeorder-page-button-right{margin-left: 1.5vw;}
.makeorder-page-button-order{
    background-color: #30AA4C;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 1.5vw;
    padding: 0 1vw;
    cursor: pointer;
}
.makeorder-page-button-order:hover{
    background-color: white;
    color: #30AA4C;
    border: 0.5px solid #30AA4C;
}
.makeorder-page-button-order-icon{
    margin-right: 0.5vw;
    font-size: 1.5em;
}
</style>
