<style lang="scss" scoped>
@import "./typings.component";

.left-align {
  text-align: left !important;
}

</style>

<template>

    <div>
        <div class="cc-loader" :class="{ hidden : !is_loading }">
            <img src="/images/loader.svg" />
            <span>Carregando Produtos</span>
        </div>
        <table class="typing-order-header" :class="{ hidden : is_loading }">
            <thead>
                <tr>
                    <th>Produto/Marca</th>
                    <th class="orders">Req</th>
                    <th class="center">Qtd. Emb</th>
                    <th class="center">Pedido</th>
                    <th class="center">Und. Compra</th>
                    <th class="center">Estoque</th>
                    <th class="center">Digitação</th>
                    <th class="center">Ult. Compra</th>
                    <th class="right-align">Total</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(product, idx) in visible_products()" v-bind="'prod-parent-wrapper-' + product.idProduto">
                <tr v-bind:key="'prod-parent-' + product.idProduto" class="first">
                    <td class="product-description">
                        <i class="fas fa-angle-right" v-if="!product.expanded" @click="toggle_collapse(product)"></i>
                        <i class="fas fa-angle-down" v-if="product.expanded" @click="toggle_collapse(product)"></i>
                        <span class="name">{{ product.produtoDescricao }}</span>
                        <br />
                        <span class="provider">{{ product.pro_observacao }}</span>
                        <template v-if="product.observacao">
                            <span class="obs-desc">
                                <span class="observation">obs:</span>
                                {{ product.observacao }}
                            </span>
                        </template>
                    </td>
                    <td class="orders">{{ product.quantidade }}</td>
                    <td class="orders">
                        {{ (product.flag_generico == 1 || product.pro_principal) ? product.quantidadeDigitacao : product.embalagem.split(" ")[1] }}
                    </td>
                    <td class="unity">
                        <div class="unity-wrapper">
                            <input
                            min="0"
                            type="number"
                            :data-idx="idx"
                            ref="product-amount"
                            :class="{ disabled : !!subrequest.id_pedido, lower: product.firstValue > product.quantidade_sugestao, higher: product.firstValue < product.quantidade_sugestao }"
                            :readonly="subrequest && !!subrequest.id_pedido"
                            v-model="product.new_amount"
                            v-on:keydown="process_event($event, product)"
                            v-on:blur="process_event($event, product)"
                            :key="'qtd-' + idx"
                            />
                            <button class="save" :disabled="!!subrequest.id_pedido" v-on:click="!product.is_loading && update_quantity(product)">
                                <img :class="{ hidden: !product.is_loading }" src="/images/loader.svg" />
                                <i v-if="!product.is_loading && product.quantidade > product.new_amount" class="fa fa-arrow-down" ></i>
                                <i v-if="!product.is_loading && product.quantidade < product.new_amount" class="fa fa-arrow-up"></i>
                                <i v-if="!product.is_loading && product.quantidade == product.new_amount" class="fa fa-check" ></i>
                            </button>
                        </div>
                    </td>
                    <td class="unity left-align">
                        {{ product.pro_unidade_compra ? product.pro_unidade_compra : product.embalagem.split(" ")[0] }}
                        <i class="far fa-edit" @click="edit_wrap(product)" v-if="product.flag_generico == 0 && !product.pro_principal && !subrequest.id_pedido"/>
                    </td>
                    <td class="storage">{{ product.estoque ? product.estoque : '' }}</td>
                    <td class="typing-price" :class="{ higher: product.menorPreco > product.ultimaCompra, lower: product.menorPreco < product.ultimaCompra }">
                        <span>{{ product.menorPreco | currency }}</span>
                        <i v-show="subrequest && !subrequest.id_pedido" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(product)"></i>
                    </td>
                    <td class="last-buy">
                        {{ product.ultimaCompra | currency }}
                    </td>
                    <td class="unity right-align">{{ product.menorPreco*product.quantidade_sugestao*( product.flag_generico == 1 || (product.pro_principal == null && product.cli_tipoPedido == 1) ? 1 : parseInt(product.embalagem.split(" ")[1])) | currency }}</td>
                </tr>

                <tr class="typings_loader" :class="{ hidden: !product.is_loading_typings }" v-bind:key="'typings-loader-' + product.idProduto">
                    <td colspan="10">
                        <img src="/images/loader.svg" />
                    </td>
                </tr>
                <tr v-bind:key="'prod-typings-' + product.idProduto" class="last" v-if="product.expanded && !product.is_loading_typings">
                    <td colspan="10">
                    <table class="product-providers">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Fornecedor</th>
                            <th>Marca</th>
                            <th>QTD. EMB</th>
                            <th>&nbsp; &nbsp; &nbsp;QTD</th>
                            <th>VL. UNITARIO</th>
                            <th>TOTAL ITEM</th>
                            <th>FAT. MIN</th>
                            <th>TOTAL FORNECEDOR</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(typing, idt) in product.digitacoes"
                            v-bind:key="typing.idFornecedor+'-prod-typing' + typing.pro_id"
                        >
                            <td>
                            <div class="checkbox-container">
                                <input
                                type="checkbox"
                                :checked="typing.vencedor_quantidade == 1"
                                v-on:click="toggle_winner(typing, product, typing.idVendedor, typing.pro_id, !(typing.vencedor_quantidade == 1))"
                                :disabled="!!subrequest.id_pedido || typing.qtdPedido > 0"
                                />
                                <span class="checkmark"></span>
                            </div>
                            </td>
                            <td>
                            <div style="position:relative;display:inline;">
                                <i v-if="typing.loading" class="fas fa-circle-notch fa-spin typing-loader"></i>
                            </div>
                            <span>{{ typing.nomeFornecedor }}</span>
                            <span
                                class="obs"
                                v-if="typing.observacao"
                            >
                                <span class="label">OBS:</span>
                                {{ typing.observacao }}
                            </span>
                            </td>
                            <td>{{ typing.pro_observacao }}</td>
                            <td>{{ typing.embalagem }}</td>
                            <td>
                              <div class="quantity-holder">
                                <input
                                    type="text"
                                    :ref="'dig-' + (typing.vencedor_quantidade != 1 ? 'dis-' : 'en-') + product.idProduto"
                                    :data-idt="idt"
                                    :class="{ disabled: typing.vencedor_quantidade != 1}"
                                    v-on:keyup="process_event_typing($event, typing, product)"
                                    v-on:blur="process_event_typing($event, typing, product)"
                                    :readonly="typing.vencedor_quantidade != 1"
                                    :disabled="!!subrequest.id_pedido"
                                    :value="typing.vencedor_quantidade == 1 ? typing.quantidade : 0"
                                />
                              </div>
                            </td>
                            <td>{{ typing.valor | currency }}</td>
                            <td>{{ typing.vencedor_quantidade == 1 ? typing.valor*typing.quantidade : 0 | currency }}</td>
                            <td>{{ typing.usu_fatura_min | currency }}</td>
                            <td>{{ typing.valorTotal | currency }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
        <NegotiationModal
            @close="reset_negotitation"
            :typing="current_typing  || {}"
            :product="current_product || {}"
            v-if="current_typing"
            :quotation_id="$route.params.id" />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="current_product"
            @close="close_wrap"
        />
    </div>
</template>
<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import ProductService from "@/services/ProductService";
import OrderService from "@/services/OrderService";
import ProviderService from "@/services/ProviderService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import AuthService from "@/services/AuthService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'

export default {
    props: {
        subrequest: Object
    },
    components: {
        ccPagination: Pagination,
        popoverLastBuy,
        NegotiationModal,
        ProductWrapModal
    },
  data() {
    return {
      is_loading: true,
      CONSTANTS: CONSTANTS,
      backRoute: 1,
      backOrdered: 0,
      backRouteName: "cliente-monitores-vencedores",
      sellers: [],
      openedPopovers: [],
      orderService: new OrderService(),
      quotationService: new QuotationService(),
      product_svc: new ProductService(),
      current_seller: null,
      current_typing: null,
      current_product: null,
      search_terms: null,
      selected_status_filter: "WINNERS_ONLY",
      quotationId: [],
      products: [],
      currentOrder: null,
      open_edit_wrap: false,
      breadcrumbs: [
        {
          link: CONSTANTS.ROUTE_MONITORS,
          name: "Monitor de Cotações"
        },
        {
          link:
            CONSTANTS.ROUTE_MONITORS + "/vencedores/" + this.$route.params.id,
          name: "Cotação " + this.$route.params.id
        },
        {
          name: "Pré-Pedidos"
        },
        {
          name: "Realizar Pedido"
        }
      ]
    };
  },
    methods: {
        close_wrap(reload = false) {
            this.current_product = null;
            this.open_edit_wrap = false
            if(reload) {
                this.$emit("reload_provider");
            }
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        open_negotiation_modal(product) {

            this.current_product = product;

            let resolve = (data) => {
                if(data) {
                    this.$set(product, "digitacoes", data.digitacoes);
                }
                this.current_typing = product.digitacoes.filter(offer => {
                    return offer.idFornecedor == this.subrequest.for_id &&
                        offer.idVendedor == this.subrequest.vendedor_id &&
                        offer.vencedor_quantidade == 1 &&
                        offer.pro_id == product.idProduto
                })[0]
            }

            if(!product.digitacoes) {
                this.load_offers(product).then(resolve)
            } else {
                resolve();
            }

        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.menorPreco = new_value
            }
            this.current_typing = null;
            if(with_reload) {
                this.load_offers(this.current_product).then(data => {
                this.$set(this.current_product, "digitacoes", data.digitacoes);
                this.$emit("reload_provider");
                })
            }
        },
        print() {
            self.print();
        },
        openPopover(popover) {
            this.openedPopovers.push(popover);
            this.$refs[popover][0].style.display = "block";
        },
        closePopover(popover) {
            const index = this.openedPopovers.indexOf(popover);
            if (index > -1) {
                this.openedPopovers.splice(index, 1);
            }
            this.$refs[popover][0].style.display = "none";
        },
        process_event_typing: function(e, typing, product) {
            e.preventDefault();
            let key = getKey(e);
            let new_amount = parseInt(e.target.value);
            let idt = parseInt(e.target.dataset.idt);
            let inputs = this.$refs["dig-en-" + product.idProduto];
            typing.new_amount = new_amount;
            typing.idProduto = typing.pro_id;
            function getNext() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    if (i == inputs.length - 1) {
                    result = inputs[0];
                    } else {
                    result = inputs[i + 1];
                    }
                    break;
                }
                }
                return result;
            }
            function getPrev() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    if (i == 0) {
                    result = inputs[inputs.length - 1];
                    } else {
                    result = inputs[i - 1];
                    }
                    break;
                }
                }
                return result;
            }
            function getCurrent() {
                let result = null;
                for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].dataset.idt == idt) {
                    result = inputs[i];
                    break;
                }
                }
                return result;
            }
            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                if (e.keyCode === 16) return "Shift";
                if (e.keyCode === 8) return "Tab";
                // etc
            }
            if (key == "Enter" || key == "ArrowDown" || key == "ArrowUp" || e.type == "blur") {
                this.update_quantity(typing, () => {
                    this.$set(typing, "quantidade", typing.new_amount);
                    if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                        this.$set(product, "new_amount", typing.new_amount);
                        this.$set(product, "quantidade_sugestao", typing.new_amount);
                    }
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });

                    if(e.type !== "blur")
                    Vue.nextTick(() => {
                        if (key == "ArrowDown") {
                            getNext().focus();
                            getNext().select()
                        } else if (key == "ArrowUp") {
                            getPrev().focus();
                            getPrev().select();
                        } else {
                            getCurrent().focus();
                            getCurrent().select();
                        }
                    });
                });
            }
        },
        process_event: function(e, product) {
            let key = getKey(e);
            let idx = parseInt(e.target.dataset.idx);
            let inputs = this.$refs["product-amount"];
            let next_idx = idx + 1 == inputs.length ? 0 : idx + 1;
            let prev_idx = idx == 0 ? inputs.length - 1 : idx - 1;
            function getKey(e) {
                if (e.key) return e.key;
                let keyFromCode = String.fromCharCode(e.keyCode);
                if (keyFromCode) return keyFromCode;
                // add here the tricky keys that you use in your app
                if (e.keyCode === 13) return "Enter";
                if (e.keyCode === 16) return "Shift";
                if (e.keyCode === 8) return "Tab";
                // etc
            }
            this.$set(product, "is_loading", true);
            if (key == "ArrowUp") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[prev_idx].focus();
                    inputs[prev_idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if (key == "ArrowDown") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[next_idx].focus();
                    inputs[next_idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if (key == "Enter") {
                e.preventDefault();
                this.update_quantity(product, () => {
                    inputs[idx].focus();
                    inputs[idx].select();
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else if(e.type == "blur") {
                this.update_quantity(product, () => {
                    product.is_loading = false;
                    this.$emit("reload_provider");
                    this.load_offers(product).then(data => {
                        this.$set(product, "digitacoes", data.digitacoes);
                    });
                });
            } else {
                // inputs[idx].focus();
                // inputs[idx].select();
                product.is_loading = false;
            }
        },
        toggle_winner(typing, product, sellerId = this.$route.params.sellerid, prod_id, will_be_winner = !this.is_winner(product)) {
            this.$set(typing, 'loading', true);
            this.quotationService
            .toggleQuotationWinner(this.$route.params.id, this.$route.params.sid, prod_id, sellerId, will_be_winner)
            .then(response => response.data)
            .then(() => {
                this.quotationService.getDigitacaesProdutoComparativo(
                    this.$route.params.id,
                    this.$route.params.sid,
                    product.idProduto
                ).then(response => response.data).then(data => {
                    this.$set(product, "digitacoes", data.digitacoes)
                    this.$set(typing, 'loading', false)
                    if (product.idProduto == typing.pro_id && typing.nomeFornecedor == this.subrequest.for_nome) {
                        if(!will_be_winner) {
                            this.$set(product, 'new_amount', 0)
                            this.$set(product, 'quantidade_sugestao', 0)
                        } else {
                            this.$set(product, 'quantidade_sugestao', product.quantidade)
                            this.$set(product, 'new_amount', product.quantidade)
                        }
                    }
                    this.$emit("reload_provider");
                })
            })
        },
        order() {
            let data = {
                idcotacao: this.$route.params.id,
                idvendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid
            }
            this.$swal.fire({
                title: "Confirma realização do pedido?",
                text: "Seu pedido será enviado ao fornecedor!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true;
                    this.quotationService.order(data)
                    .then(response => response.data)
                    .then(data => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Realizado!",
                            "Seu pedido foi realizado com sucesso.",
                            "success"
                        );
                        this.update();
                    }, err => {
                        this.is_loading = false;
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao realizar o pedido.",
                            "error"
                        );
                        }
                    );
                }
            });
        },
        update_quantity(product, callback) {
            if (!product.new_amount) product.new_amount = "0";
            let payload = {
                numerocotacao: this.$route.params.id,
                quantidade: product.new_amount,
                vendedor: product.idVendedor
                ? product.idVendedor
                : this.$route.params.sellerid,
                produto: product.idProduto,
                loja: this.$route.params.sid
            };
            this.$set(product, "new_amount", payload.quantidade);
            let productLoading = product.is_loading;
            this.$set(product, "is_loading", true);
            return this.quotationService
            .update_quantity(payload)
            .then(response => response.data)
            .then(data => {
                if(!productLoading){
                    product.is_loading = false;
                }
                this.$set(product, "quantidade_sugestao", payload.quantidade);
                callback();
                this.reload_subrequest();
            }, err => {
                product.is_loading = false;
            })
        },
        cancel_order() {
            let data = {
                numeroCotacao: this.$route.params.id,
                vendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid
            };
            this.$swal
                .fire({
                title: "Confirma cancelamento do pedido?",
                text: "Seu pedido será cancelado!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, cancelar pedido!"
                })
                .then(result => {
                if (result.value) {
                    this.quotationService
                    .cancel_order(data)
                    .then(response => response.data)
                    .then(
                        data => {
                            this.$swal.fire(
                                "Sucesso!",
                                "Seu pedido foi cancelado com sucesso.",
                                "success"
                            );
                            this.update();
                        },
                        err => {
                        this.$swal.fire(
                            "Erro!",
                            "Ocorreu um erro ao cancelar o pedido.",
                            "error"
                        );
                        }
                    );
                }
            });
        },
        reload_subrequest() {
            // TODO
        },
        checkReturnRoute() {
            if(this.subrequest) {
                if(this.subrequest.id_pedido) {
                    this.backOrdered = 0;
                } else if(this.subrequest.id_pedido) {
                    this.backOrdered = 1;
                }
            }
        },
        has_observation(product) {
            return product.observacao;
        },
        is_winner(product) {
            return (product.vencedor_multiplo == 1 || product.vencedor == 1) && product.quantidade_sugestao > 0;
        },
        visible_products() {
            return this.products.filter(p => p.visible == undefined || p.visible);
        },
        search(evt) {
            this.products.forEach(p => {
                if (new RegExp(this.search_terms, "i").test(p.descricaoProduto) && this.is_winner(p)) {
                    this.$set(p, "visible", true);
                } else {
                    this.$set(p, "visible", false);
                }
            })
        },
        filter_by_status() {
            this.products.forEach(p => {
                if (this.is_winner(p)) {
                    this.$set(p, "visible", true);
                } else {
                    this.$set(p, "visible", false);
                }
            })
        },
        subrequest_total() {
            return this.subrequest.valorTotal;
        },
        toggle_collapse(product, force_reload = false) {
            if (!product.digitacoes || force_reload) {
                this.$set(product, "is_loading_typings", true);
                this.load_offers(product, force_reload).then(data => {
                this.$set(product, "is_loading_typings", false);
                this.$set(product, "digitacoes", data.digitacoes);
                this.$set(product, "expanded", !product.expanded || force_reload);
                });
            } else {
                this.$set(product, "expanded", !product.expanded);
            }
        },
        load_offers(product) {
            return this.quotationService.getDigitacaesProdutoComparativo(
                this.$route.params.id,
                this.$route.params.sid,
                product.idProduto
            ).then(response => response.data)
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getProducts() {
            var quotationService = new QuotationService();
            quotationService.getProdutosDigitacaoComparativo(
                this.$route.params.id,
                this.$route.params.sellerid,
                this.$route.params.sid
            ).then(response => response.data).then(data => {
                let productsArray = data.produtos;
                productsArray.forEach(p => {
                    p.new_amount = p.quantidade_sugestao;
                    p.firstValue = p.quantidade_sugestao;
                });
                this.products = productsArray;
                this.total_items = data.total;
                if (data.valorTotal)
                    data.valorTotal.forEach(d => (this.total_pre_orders += d.total));
                this.filter_by_status();
                this.is_loading = false;
            }).catch(error => ErrorHandlerService.handle(error, this.$store))
        },
        update() {
            this.is_loading = true;
            this.checkReturnRoute();
            this.getProducts();
        }
    },
    created() {
        this.update();
        if (this.$route.query.backRoute == 2) {
            this.backRoute = 2;
            this.backRouteName = this.$route.params.backRouteName;
        }
    }
};
</script>
